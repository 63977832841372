import React from 'react'
import styled from 'styled-components'
import config from './config.json'

const StyledNavbar = styled.header`
  width: 100%;
  border-bottom: 1px solid #ddd;
  background: ${config.theme.bagBlue};
  text-align: center;
  position: fixed;
  height: ${config.theme.headerHeight}px;
  z-index: 2;
  h1 {
    color: #fff;
  }
  box-shadow: 0 2px 4px #ddd;
`

const Navbar = () => {
  return <StyledNavbar>
    <h1>Brownout-Infoservice</h1>
  </StyledNavbar>
}

export default Navbar

import React from 'react'
import styled from 'styled-components'
import config from './config.json'

const StyledDesktopFooter = styled.footer`
.desktop{
  width: 100%;
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 0;
  background: #fff;
  border-top: 1px solid #D7D7D7;
}

.mobile-footer {
  margin-top: 8px;
}
.mobile-footer .footer-item {
  width: 100%;
  text-align: center;
}
.footer-container {
    max-width: ${config.theme.maxWidth}px;
    width: 100%;
    display: flex;

    .left {
      display: flex;
    }
    .right {
      display: flex;
      margin-left: auto;
    }

    .footer-item {
      padding: 16px;
      color: #6E6E6E;
      font-size: 12px;
    }
  }

`

const NavigationFooter = () => {
  return <StyledDesktopFooter>
    <div className="desktop-footer hidden-xs visible-sm">
      <div className="desktop">
        <div className="footer-container">
          <div className="left">
            <span className="footer-item copyright">© {new Date().getFullYear()} Bayernwerk Netz GmbH</span>
          </div>
          <div className="right">
            <a href="https://www.bayernwerk.de/de/ueber-uns/disclaimer.html" className="footer-item" role="link" target="_blank" rel="noreferrer">AGB</a>
            <a href="https://www.bayernwerk.de/de/ueber-uns/impressum.html" className="footer-item" role="link" target="_blank" rel="noreferrer">Impressum</a>
            <a href="https://www.bayernwerk.de/de/ueber-uns/datenschutz.html" className="footer-item" role="link" target="_blank" rel="noreferrer">Datenschutz</a>
            <a href="licenses.txt" className="footer-item" role="link" target="_blank" rel="noreferrer">Lizenzen</a>
          </div>
        </div>
      </div>
    </div>
    <div className="mobile-footer hidden-sm visible-xs">
      <span className="footer-item copyright col-xs-12">© 2022 Bayernwerk Netz GmbH</span>
      <a href="https://www.bayernwerk.de/de/ueber-uns/disclaimer.html" className="footer-item  col-xs-12" role="link" target="_blank" rel="noreferrer">AGB</a>
      <a href="https://www.bayernwerk.de/de/ueber-uns/impressum.html" className="footer-item col-xs-12" role="link" target="_blank" rel="noreferrer">Impressum</a>
      <a href="https://www.bayernwerk.de/de/ueber-uns/datenschutz.html" className="footer-item col-xs-12" role="link" target="_blank" rel="noreferrer">Datenschutz</a>
      <a href="licenses.txt" className="footer-item col-xs-12" role="link" target="_blank" rel="noreferrer">Lizenzen</a>
    </div>
  </StyledDesktopFooter>
}

export default NavigationFooter

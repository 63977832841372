import React, { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import ResponsiveGrid, { getCurrentBreakpoint } from 'wnderlvst-grid'
import styled from 'styled-components'
import 'core-js'
import queryString from 'query-string'
import api from '../api.json'
import saveInfo from './saveInfo'
import Footer from './Footer'
import config from './config.json'
import Navbar from './Navbar'
import Polo22Buch from '../assets/fonts/Polo22/Polo22-Buch.woff2'
import Polo22Krftg from '../assets/fonts/Polo22/Polo22-Krftg.woff2'
import Polo22Leicht from '../assets/fonts/Polo22/Polo22-Leicht.woff2'
import bayernwerkLogo from '../assets/images/bayernwerk-logo.png'
import headerImage from '../assets/images/header.png'
import phoneValidator from 'phone'
import clsx from 'clsx'

const StyledApp = styled.div`  
  .navbar-spacer {
    height: ${config.theme.headerHeight + 8}px;
  }
  .content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .content {
    max-width: ${config.theme.maxWidth}px;
    width: 100%;
  }
  
  eon-ui-input {
    margin: 32px 0;
  }

  .centered-floating-content {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: #DDDDDD80;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .popup {
    padding: 8px;
    display: flex;
    align-items: center;
  }

  .paper {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px #ddd;
    margin: 8px;
  }

  @font-face {
    font-family: 'Polo22Buch';
    src: url(${Polo22Buch}) format('woff2');
  }
  @font-face {
    font-family: 'Polo22Krftg';
    src: url(${Polo22Krftg}) format('woff2');
  }
  @font-face {
    font-family: 'Polo22Leicht';
    src: url(${Polo22Leicht}) format('woff2');
  }

  .datenschutz-container {
    font-size: 12px;
  }
  .bayernwerk-logo {
    height: 64px;
    position: absolute;
    background: white;
    right: 0;
    bottom: 50%;
    z-index: 1;
    padding: 8px;
    border-radius: 8px 0 0 8px;

    overflow: hidden;
    &.fadedIn {
    }

  }
  .logo-container {
    margin-top: 24px;
    text-align: center;
  }

  .content-form {
    padding: 16px;
  }
  .information-container {
    font-size: 16px;
    margin-top: 24px;
  }
  .phone-validation-indicator {
    font-size: 12px;
  }
  .error-text {
    color: #B80E06;
  }
  [name="phone-number-input"] {
    margin-bottom: 8px;
  }
  .header-image {
    background: url(${headerImage});
    height: 200px;
    border-radius: inherit;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
  .header-image-container {
    position: relative;
    border-radius: 8px 8px 0 0;
  }
  .header-image-opacity {
    width: 100%;
    height: 100%;
    position: absolute;
  }
  b {
    font-weight: 800;
  }
  
  * {
    font-family: 'Polo22Buch';
    font-weight: 400;
    font-style: normal;
    color: #333;
    letter-spacing: 0.01em !important;
  }
`

const getCurrentApiKey = stage => (config.apiKeys[stage])

const App = () => {
  const [currentBreakpoint, setCurrentBreakpoint] = useState(getCurrentBreakpoint())
  const [currentState, setCurrentState] = useState('loading')
  const [currentFormState, setCurrentFormState] = useState('idle')
  const [fadeIn, setFadeIn] = useState(false)
  const [ags, setAgs] = useState()
  const [key, setKey] = useState()
  const [tempAGS, setTempAGS] = useState()
  const [tempKey, setTempKEY] = useState()
  const [phone, setPhone] = useState('')
  const [isPhoneValid, setPhoneValid] = useState(true)
  const [mail, setMail] = useState('')
  const [gemeinde, setGemeinde] = useState('...')
  const [currentApi, setCurrentApi] = useState(null)
  const [currentStage, setCurrentStage] = useState(null)
  const agsRef = useRef()
  const keyRef = useRef()
  const phoneRef = useRef()
  const mailRef = useRef()

  useEffect(() => {
    const { ags, key } = queryString.parse(window.location.hash)
    if (!key || !ags) {
      return setCurrentState('missing-info')
    }
    setTimeout(() => {
      setCurrentState('loading')
      setAgs(ags)
      setKey(key)
    }, 500)
  }, [])

  useEffect(() => {
    if (!phone && !mail) {
      return setCurrentFormState('idle')
    }

    setCurrentFormState('ready')
  }, [phone, mail])

  useEffect(() => {
    if (!ags || !key || !currentApi || !currentStage) {
      return
    }
    setCurrentState('loading')

    async function fetchInfo () {
      try {
        const response = await window.fetch(currentApi + '/contacts/' + ags, {
          mode: 'cors',
          headers: {
            'X-Key': key,
            'Ocp-Apim-Subscription-Key': getCurrentApiKey(currentStage),
            Accept: '*/*'
          }
        }).then(res => {
          if (!res.ok) {
            throw Error(res.statusText)
          }
          return res
        })

        const data = await response.json()

        setPhone(data.mobile)
        setMail(data.email)
        setGemeinde(data.muncipality)
        setTimeout(() => {
          setCurrentState('success')
        }, 500)
      } catch (e) {
        console.error(e)
        setCurrentState('error')
      }
    }
    fetchInfo()
  }, [ags, key, currentApi, currentStage])

  useEffect(() => {
    async function fetchData () {
      try {
        const response = await window.fetch('//' + window.location.host + '/api.json').then(data => data.json())
        const apiEndpoint = response.api_endpoint
        const stage = response.stage
        setCurrentApi(apiEndpoint)
        setCurrentStage(stage)
      } catch (e) {
        console.error(e)
        setCurrentApi(api.api_endpoint)
        setCurrentStage(api.stage)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    if (!agsRef.current || !keyRef.current) {
      return
    }
    agsRef.current.addEventListener('valueChanged', ({ target: { value } }) => setTempAGS(value), false)
  }, [ags, key, currentState])
  useEffect(() => {
    if (!agsRef.current || !keyRef.current) {
      return
    }
    keyRef.current.addEventListener('valueChanged', ({ target: { value } }) => setTempKEY(value), false)
  }, [ags, key, currentState])

  useEffect(() => {
    if (!phoneRef.current || !mailRef.current) {
      return
    }

    phoneRef.current.addEventListener('valueChanged', ({ target: { value } }) => {
      setPhone(value)
    }
    , false)
  }, [ags, key, currentState])

  useEffect(() => {
    if (!mailRef.current || !mailRef.current) {
      return
    }

    mailRef.current.addEventListener('valueChanged', ({ target: { value } }) => setMail(value), false)
  }, [ags, key, currentState])

  useEffect(() => {
    if (currentFormState !== 'saving') {
      return
    }

    setCurrentState('loading')
  }, [currentFormState])

  useEffect(() => {
    if (currentFormState !== 'saving') {
      return
    }

    saveInfo({ currentApi, ags, key, mail, phone, setCurrentState, setCurrentFormState, setPhone, setMail, apiKey: getCurrentApiKey(currentStage) })
    setCurrentState('loading')
  }, [currentFormState, mail, phone])

  useEffect(() => {
    // let { isValid } = phoneValidator(phone, { country: 'DE' })
    let isValid = (phone || '').match(/^\+49\s\d{2,4}\s\d{2,8}?$/)
    if (phone === '' || phone === null) {
      isValid = true
    }

    setPhoneValid(isValid)
    console.log('isValid: ' + isValid)
  }, [phone])

  useEffect(() => {
    setTimeout(() => setFadeIn(true), 100)
  }, [])
  return (<StyledApp>
    <Navbar></Navbar>
    <div className="navbar-spacer"></div>
    <div className="content-container">
      <div className="content">
        <div className="paper">
          <div className="header-image-container">
            <img className={clsx('bayernwerk-logo', fadeIn && 'fadedIn')} src={bayernwerkLogo}></img>
            <div className="header-image-opacity"></div>
            <div className="header-image"></div>
          </div>
          <div className="col-xs-12 col-md-6">
            <eon-ui-text>{gemeinde}</eon-ui-text>
            <eon-ui-input name="phone-number-input" type="tel" label="Mobilfunknummer" value={phone} ref={phoneRef}
              placeholder="+49 123 456789"
            ></eon-ui-input>
             <div className={clsx('phone-validation-indicator', !isPhoneValid && 'error-text')}>{!isPhoneValid && 'Die angegebene Mobilfunknummer ist falsch formatiert!' || <span>&nbsp;</span>}</div>
            <eon-ui-input name="mail-input" type="email" label="E-Mail" value={mail} ref={mailRef} placeholder="mail@example.com"></eon-ui-input>
            <eon-ui-button
            icon={
              currentFormState === 'ready' && 'save-4px' ||
                currentFormState === 'saving' && 'page-controller-loading-3-4px' ||
                  currentFormState === 'success' && 'interface-validation-check-circle-alternate-4px' ||
                  'save-4px'
            } text={currentFormState === 'ready' && 'Speichern' ||
            currentFormState === 'saving' && 'Lädt...' ||
            currentFormState === 'success' && 'Gespeichert' || 'Speichern'} onClick={() => {
              setCurrentFormState('saving')
            }}
            disabled={!isPhoneValid}
            scheme="bayernwerkPrimary"></eon-ui-button>
          </div>

          <div className="col-xs-12 col-md-6">
            <eon-ui-text>Hinweis</eon-ui-text>
            <div className="information-container">
                <p>Bitte geben Sie hier in den Eingabefeldern eine Mobilfunktelefonnummer und/oder eine E-Mail-Adresse an, über welche Sie alarmiert werden möchten. Dies sollten bevorzugt Funktions-Postfächer, Sammel-E-Mail-Adressen, etc. sein und keine personenbezogenen Kontaktdaten. Andernfalls stellen Sie bitte sicher, dass die personenbezogenen Kontaktdaten für diesen Zweck verwendet werden dürfen (z. B. Einwilligung der betroffenen Personen).</p>
                <p>Das Format für die Mobilfunknummer lautet: <b>+49 123 456789</b></p>
            </div>
          </div>

        </div>

        {currentState === 'loading' && <div className="centered-floating-content">
          <div className="paper popup">
            <eon-ui-preloader-circle scheme="bayernwerkSecondary"></eon-ui-preloader-circle>
            <eon-ui-text text-style="copy">Brownout Info wird geladen</eon-ui-text>
          </div>
        </div>}
        {currentState === 'error' && <div className="centered-floating-content">
          <div className="paper popup">
            <eon-ui-icon name="warning-circle-4px" scheme="bayernwerkSecondary"></eon-ui-icon>
            <eon-ui-text text-style="copy">Fehler beim Laden der Brownout Info</eon-ui-text>
            </div>
        </div>}
        {currentState === 'missing-info' && <div className="centered-floating-content">
          <div className="content-form paper">
            <eon-ui-text text-style="copy">Bitte die fehlenden Informationen eintragen</eon-ui-text>
            <eon-ui-input name="ags-input" type="number" label="ags" ref={agsRef} value={tempAGS} onChange={e => {
              setTempAGS(e.value)
            }}></eon-ui-input>
            <eon-ui-input name="key-input" type="text" label="key" ref={keyRef} value={tempKey} onChange={e => setTempKEY(e.value)}></eon-ui-input>
            <eon-ui-button text="Übernehmen" disabled={!tempAGS || !tempKey}
            onClick={() => {
              const qsp = queryString.stringify({ ags: tempAGS, key: tempKey })
              window.location.hash = qsp
              window.location.reload()
            }} scheme="bayernwerkPrimary"></eon-ui-button>
          </div>
        </div>}
        <div className="datenschutz-container col-xs-12">
          Hinweis zu unseren Datenschutzbestimmungen:
          Die von Ihnen an das Bayernwerk übermittelten Kontaktdaten (E-Mailadresse, Mobilfunktelefonnummer) werden auf Basis Ihrer Anmeldung ausschließlich zum Zwecke der Informationsübermittlung im Falle eines Brownouts (ggf. Blackouts) im Alarmierungssystem hinterlegt. Rechtsgrundlage ist Art. 6 Abs. 1 lit. b) DSGVO. Die Kontaktdaten werden längstens für die Dauer des Betreibens der Alarmierungsplattform im System gespeichert. Sie können sich jederzeit vom Service abmelden und Ihre Daten löschen lassen. Als Alarmierungssystem wird die externe Plattform FACT24 genutzt. Die hinterlegten Kontaktdaten sind für den Betreiber ggf. einsichtig.
          Weitere Informationen zum Verantwortlichen und zu Ihren rechten finden Sie unter:&nbsp;
          <a href="https://www.bayernwerk.de/de/ueber-uns/datenschutz.html" className="footer-item" role="link" target="_blank" rel="noreferrer">Datenschutz (bayernwerk-netz.de)</a>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </StyledApp>)
}

document.querySelector('html').style.background = '#FAFAFA'
document.querySelector('body').style.margin = 0

ReactDOM.render(
    <ResponsiveGrid>
      <App />
    </ResponsiveGrid>,
    document.getElementById('root')
)

async function saveInfo ({ currentApi, ags, key, mail, phone, setCurrentState, setCurrentFormState, setPhone, setMail, apiKey }) {
  try {
    const response = await window.fetch(currentApi + '/contacts/' + ags, {
      mode: 'cors',
      method: 'PUT',
      headers: {
        'X-Key': key,
        'Ocp-Apim-Subscription-Key': apiKey,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        ags,
        email: mail,
        mobile: phone
      })
    }).then(res => {
      if (!res.ok) {
        throw Error(res.statusText)
      }
      return res
    })

    setCurrentFormState('success')
    setCurrentState('success')
  } catch (e) {
    console.error(e)
    setCurrentState('error')
  }
}

export default saveInfo
